export default {
    'UPDATE_PARTICIPANTS_LIST'(state, data){
        state.participantsList = data;
    },
    'UPDATE_PARTICIPANTS_GROUPS'(state, data) {
      state.participantsGroups = data?.data
    },
    'UPDATE_PARTICIPANTS_META'(state, data) {
      state.participantsMeta = data
    }
}
