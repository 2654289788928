import {loadScript} from "@/utils/helpers";

// GUIDE https://developers.google.com/analytics/devguides/collection/gtagjs/screens?hl=ru
// Enhanced ecommerce -  https://developers.google.com/analytics/devguides/collection/gtagjs/enhanced-ecommerce?hl=ru
// Event parameters - https://support.google.com/analytics/answer/9143382?sjid=9744742990624214877-EU#zippy=%2C%D1%81%D0%BE%D0%B1%D1%8B%D1%82%D0%B8%D0%B5%2C%D1%8D%D0%BB%D0%B5%D0%BA%D1%82%D1%80%D0%BE%D0%BD%D0%BD%D0%B0%D1%8F-%D1%82%D0%BE%D1%80%D0%B3%D0%BE%D0%B2%D0%BB%D1%8F
// Event parameters - https://developers.google.com/gtagjs/reference/ga4-events?hl=ru
// Structure = category > actions
// {
// category: 'video'
// actions: 'watch',
// label: 'CEDAR RAPIDS @ NTDP U17'
// }

const GA4_EVENTS = {
    config: 'config',
    login: 'login',
    page_path: 'page_path',
    page_view: 'page_view',
    view_buy_page: 'view_buy_page',
    buy_order: 'buy_order',
    view_item_list: 'view_item_list',
    select_item: 'select_item',
    add_to_cart: 'add to cart',
    begin_checkout: 'begin_checkout',
    purchase: 'purchase',
    purchase_success: 'purchase_success',
    purchase_failed: 'purchase_failed',
}

const TRACK_TYPE = {
    event: 'event',
    set: 'set'
}

const  GA4_EVENT_CATEGORIES = {
    ecommerce: 'ecommerce',
    engagement: 'engagement',
    // you can add custom category
}

class GTag {
    #gtagUrl = 'https://www.googletagmanager.com/gtag/js';
    #scriptLoaded = false;
    #trackID = null;
    #currency = 'USD';

    constructor() {}

    set currency (currency) {
        this.#currency = currency
    }
    set trackingId (id){
        if (!id) return false;
        this.#trackID = id;
        this.#initScript()
    }
    //gtag function
    #track(){
        if (!this.#scriptLoaded) return;
        window.dataLayer.push(arguments);
    }
    #scriptOnload(){
        this.#scriptLoaded = true;
        window.dataLayer = window.dataLayer || [];
        this.#track('js', new Date());
        this.#track(GA4_EVENTS.config, this.#trackID, {
            send_page_view: false,
            debug_mode : true,
        });
    }

    #initScript(){
        const url = new URL(this.#gtagUrl)
        url.searchParams.set('id', this.#trackID)
        loadScript(url.href, () => this.#scriptOnload())
    }

    // TRACKING INTERFACE
    login(method){
        this.#track(TRACK_TYPE.event, GA4_EVENTS.login, {'method': method}); // method = Google, Facebook, site
    }
    pageView({page_title, page_location, page_path}){
        this.#track(TRACK_TYPE.set, GA4_EVENTS.page_path, page_path)
        this.#track(TRACK_TYPE.event, GA4_EVENTS.page_view, {
            page_title,
            page_location,
            page_path,
        })
    }

    viewItemList({typedList}){
        const preparedItems = [];
        Object.entries(typedList)
            .forEach?.(([type, list]) => {
                list?.forEach?.((item) => {
                    preparedItems.push({
                        item_id: item.id,
                        item_name: item.name,
                        currency: this.#currency,
                        item_category: item.event_category_id,
                        item_list_name: type,
                        item_variant: type,
                        price: parseFloat(item.base_price),
                        quantity: 1,
                    })
                })
        })

        this.#track(TRACK_TYPE.event, GA4_EVENTS.view_item_list, {
            item_list_name: 'Passes',
            items: preparedItems,
        })
    }
    viewBuyPage({type, id, from, value = 1}){
        this.#track(TRACK_TYPE.event, GA4_EVENTS.view_buy_page, {
            event_category: GA4_EVENT_CATEGORIES.ecommerce,
            event_label: `${type} - ${id}`,
            non_interaction: false,
            referrer_page: from,
            value,
        })
    }
    beginPurchase({item, type, id, name, from}){
        this.#track(TRACK_TYPE.event, GA4_EVENTS.add_to_cart, {
            currency: this.#currency,
            value: parseFloat(item.base_price),
            referrer_page: from,
            items: [
                {
                    item_id: id,
                    item_name: name,
                    item_category: item.event_category_id,
                    item_list_name: type,
                    item_variant: type,
                    price: parseFloat(item.base_price),
                    quantity: 1,
                }
            ]
        })
    }
    beginCheckout({item, couponName, discount = 0, type, id, name}){
        this.#track(TRACK_TYPE.event, GA4_EVENTS.begin_checkout, {
            currency: this.#currency,
            value: parseFloat(item.base_price) - parseFloat(String(discount)),
            coupon: couponName,
            items: [{
                item_id: id,
                item_name: name,
                coupon: couponName,
                discount: parseFloat(String(discount)),
                item_variant: type,
                price: parseFloat(item.base_price),
                quantity: 1,
            }]
        })

    }
    processPurchase({item, couponName, discount = 0, type, id, name, transaction_id, tax}){
        this.#track(TRACK_TYPE.event, GA4_EVENTS.purchase, {
            currency: this.#currency,
            transaction_id,
            value: parseFloat(item.base_price) - parseFloat(String(discount)) + parseFloat(tax),
            coupon: couponName,
            tax: parseFloat(tax),
            items: [
                {
                    item_id: id,
                    item_name: name,
                    coupon: couponName,
                    discount: parseFloat(String(discount)),
                    item_variant: type,
                    price: parseFloat(item.base_price),
                    fee: parseFloat(item.service_fee),
                    quantity: 1,
                }
            ]
        })
    }
    successPurchase({item, couponName = null, discount = 0, type, id, name, transaction_id, tax}){
        this.#track(TRACK_TYPE.event, GA4_EVENTS.purchase_success, {
            currency: this.#currency,
            transaction_id,
            value: parseFloat(item.base_price) - parseFloat(String(discount)) + parseFloat(tax),
            coupon: couponName,
            tax: parseFloat(tax),
            items: [
                {
                    item_id: id,
                    item_name: name,
                    coupon: couponName,
                    discount: parseFloat(String(discount)),
                    item_variant: type,
                    price: parseFloat(item.base_price),
                    fee: parseFloat(item.service_fee),
                    quantity: 1,
                }
            ]
        })
    }
    failedPurchase({item, couponName, discount = 0, type, id, name, transaction_id, tax, error_reason}){
        this.#track(TRACK_TYPE.event, GA4_EVENTS.purchase_failed, {
            currency: this.#currency,
            transaction_id,
            value: parseFloat(item.base_price) - parseFloat(String(discount)) + parseFloat(tax),
            coupon: couponName,
            tax: parseFloat(tax),
            error_reason: error_reason,
            items: [
                {
                    item_id: id,
                    item_name: name,
                    coupon: couponName,
                    discount: parseFloat(String(discount)),
                    item_variant: type,
                    price: parseFloat(item.base_price),
                    fee: parseFloat(item.service_fee),
                    quantity: 1,
                }
            ]
        })
    }
}
export default new GTag()