import Vue from 'vue';
import store from '../store/store';
import {API, LOCAL_TIME_DISPLAYING, TIME_ZONE_FROM_SHORT} from "../const";
import EdjsParser from "editorjs-parser";
import {teamName} from "./helpers";

/**
 * Get corrected path with custom prefix
 * @param url {string}
 * @param prefix {string}
 * @returns {string}
 */
const fixPath = (url, prefix) => {
    if (url.startsWith('http')){
        return url
    }
    const correctUrl =  url.startsWith('/') ? url : `/${url}`
    return  prefix + correctUrl;
}
/**
 * Get source path
 * @param url
 * @returns {string|*}
 */
const getCorrectPath = (url) => {
    if (!url) return;
    return  fixPath(url, window.document.location.origin)
}

/**
 * Get image path
 * @param url
 * @returns {string|*}
 */
Vue.prototype.getImagePath = (url) => {
    if (!url) return;
    return  fixPath(url, API.MEDIA_HOST)
};

/**
 *
 * @param data
 * @param config
 * @returns {string}
 */
let paragraph_handler = function(data, config) {
    return `<p class="${config.paragraph.pClass} text-${data.alignment}"> ${data.text} </p>`;
};
let customParsers = {
    /**
     * Override paragraph for processing align
     * @param data
     * @param config
     * @returns {string}
     */
    paragraph: paragraph_handler,
    'static-block': function(data) {
        if ( data && data.content && ~data.content.indexOf(':') ) {
            let path = data.content.split(':'),
                static_items = store.state.channel.settings.static_blocks;

            if ( typeof static_items[path[0]] !== "undefined" && typeof static_items[path[0]][path[1]] !== "undefined" ) {
                let content = '';
                if ( Array.isArray( static_items[path[0]][path[1]] ) ) {
                    const parser = new EdjsParser(undefined, {
                        paragraph: paragraph_handler
                    });
                    static_items[path[0]][path[1]].map((item)=>{
                        if ( typeof item['blocks'] !== "undefined" ) {
                           content += parser.parse( item );
                        }
                    })
                }
                return content;
            }
        }
        return ''
    },
    image: function (data, config) {
        const imageConditions = `${data.stretched ? "img-fullwidth" : ""} ${
            data.withBorder ? "img-border" : ""
        } ${data.withBackground ? "img-bg" : ""}`;
        const imgClass = config.image.imgClass || "";
        const linkImage = {
            open: data.wrappedLink ? `<a href=${getCorrectPath(data.wrappedLink)} target=${data.linkTargetBlank? "_blank" : "_self"}>` : '',
            close: data.wrappedLink? '</a>' : ''
        }
        let imageSrc;

        if (data.url) {
            imageSrc = data.url;
        } else if (config.image.path === "absolute") {
            imageSrc = data.file.url;
        } else {
            imageSrc = config.image.path.replace(
                /<(.+)>/,
                (match, p1) => data.file[p1]
            );
        }

        if (config.image.use === "img") {
            return `${linkImage.open}<img class="${imageConditions} ${imgClass}" src="${imageSrc}" alt="${data.caption}">${linkImage.close}`;
        } else if (config.image.use === "figure") {
            const figureClass = config.image.figureClass || "";
            const figCapClass = config.image.figCapClass || "";

            return `<figure class="${figureClass}">
                    ${linkImage.open}<img class="${imgClass} ${imageConditions}" src="${imageSrc}" alt="${data.caption}">${linkImage.close}
                    <figcaption class="${figCapClass}">${data.caption}</figcaption>
                    </figure>`;
        }
    },
};

const parser = new EdjsParser(undefined, customParsers);
const dayFormatter = (opt) => new Intl.DateTimeFormat(Vue.i18n.locale(), opt);

Vue.prototype.getMonthAndDay = (timestamp, monthForm = 'long') => {
    timestamp = timestamp * 1000;
    let formatter = dayFormatter({
        day: "numeric",
        month: monthForm,
    });
    return formatter.format(timestamp);
};

Vue.prototype.getHoursAndMinutes = (timestamp, withTimezone = true) => {
    timestamp = timestamp * 1000;
    let opt = {
        hour: "numeric",
        minute: "numeric"
    };

    if(withTimezone) {
        opt.timeZoneName = "short"
    }

    let formatter = dayFormatter(opt);
    return formatter.format(timestamp);
};

Vue.prototype.getYear = (timestamp, yearForm = 'numeric') => {
    timestamp = timestamp * 1000;
    let formatter = dayFormatter({
        year: yearForm
    });

    return formatter.format(timestamp);
};


Vue.prototype.getFormattedDate = ({
                                      ts,
                                      timeZone,
                                      formatsOptions,
                                      asLocalTime = LOCAL_TIME_DISPLAYING
                                  }) => {
    let date = new Date(ts * 1000);
    const options = {};
    const locale = Vue.prototype.$i18n.locale();
    if (!asLocalTime && timeZone) {
        options.timeZone = TIME_ZONE_FROM_SHORT[timeZone] || timeZone;
    }
    const formattedDate = {}
    Object.entries(formatsOptions).forEach(([key, opt={}]) => {
        const toLocaleStringFuncCallers = ['hh'];
        let funcName = 'toLocaleDateString';
        if (toLocaleStringFuncCallers.includes(key)) { funcName = 'toLocaleString'}
        formattedDate[key] =  date[funcName]?.(locale, {...options, ...opt})
    })

    const zoneOption = {
        timeZoneName: "short"
    }
    if (!asLocalTime && timeZone) {
        zoneOption.timeZone = TIME_ZONE_FROM_SHORT[timeZone] || timeZone;
    }
    let zone = date.toLocaleTimeString(locale, {...options, ...zoneOption})
    zone = zone.split(' ')
    zone = zone[zone.length - 1]

    formattedDate.zone = zone
    return formattedDate;
}

Vue.prototype.trimParticipant = (name) => {
    return teamName(name);
};

Vue.prototype.convertEditorJsonToHtml = (json) => {
    // check for empty
    if (!json) {
        return '';
    }

  let html;

    try {
    if (typeof json === 'string') {
      json = JSON.parse(json);
    }
    html = parser.parse(json);
  } catch (e) {
    html = '';
  }

  return html;
};
