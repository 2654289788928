import Vue from 'vue'

export default {
  'UPDATE_CHANNEL_KEY'(state, data) {
    state.channel_key = data.key;
    Vue.$storage.set('content_type', data.content_type);
    Vue.$storage.set('channel_key', data.key);
    // Vue.$storage.set('subdoman', data.subdoman);
  },

  'UPDATE_API_KEY'(state, data) {
    state.api_key = data.data.api_key;
    Vue.$storage.set('api_key', data.data.api_key);
  },
  'UPDATE_USER_INFO' (state, data){
    if (data && data.data){
      state.user = data.data;
    }

  },
    'CLEAR_USER_INFO' (state, data){
      state.user = null;
      Vue.$storage.remove('api_key');
    },
}
