import api from '../../api/api';
import ls from '@/utils/locale-storage';

export default {
  POST_ADD_FAVORITE: async ({ dispatch }, params) => {
    const response = await api.favoriteApi.post_add_favorite(params);
    await dispatch('GET_USER_FAVORITES', {api_key: params.api_key});
    return response;
  },
  GET_DELETE_FAVORITE: async ({ dispatch },params) => {
    let rememberList = ls.get('eventsRememberList') || [];
    const response = await api.favoriteApi.get_delete_favorite(params);
     if (
        rememberList.some((rememberItem) => rememberItem.id === params.id && rememberItem.type === params.favorite_type)
      ) {
       rememberList = rememberList.filter((listItem) => {
          return !(listItem.id === params.id && listItem.type === params.favorite_type);
        });
        ls.set('eventsRememberList', rememberList);
      }
    await dispatch('GET_USER_FAVORITES', {api_key: params.api_key});
    return response;
  },
  GET_USER_FAVORITES: async ({ state, commit }, params) => {
    commit(
      'UPDATE_USER_FAVORITES',
      await api.favoriteApi.get_user_favorites(params)
    );
  },
};
