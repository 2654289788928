import mutations from './mutations';

export const initialState = {
  toastList: [],
}

export default {
  state: {
  ...initialState
  },
  mutations,
}