    //@author Lucas Mello <lucas.mello@icaromediagroup.com>
    //model to call clips api

import request from "./request";

export default {
  get_user_favorites(params) {
    return request("/favorite", params, "get"); 
  },
  get_delete_favorite(params) { 
    return request(`/favorite/delete/${params.favorite_type}/${params.id}`, params.api_key, "get"); 
  },
  post_add_favorite(params) {
    return request(`/favorite`, params, "post"); 
  },
};
