import api from '../../api/api';

export default {
      'POST_SEND_CLIP': async ({ dispatch }, params) => {
        const response = await api.clipApi.post_send_clip(params);
        // Call the GET_ALL_ID_CLIPS action after the POST_SEND_CLIP API call is completed
        await dispatch('GET_ALL_ID_CLIPS', {id: params.event_id});
        return response;
      },
      'DELETE_CLIP': async (_,params ) => {
        const response =  await api.clipApi.delete_clip(params)

        await dispatch('GET_ALL_ID_CLIPS', {id: params.event_id});
        return response;
      },
      'GET_DELETE_CLIP': async (_,params ) => {
        return await api.clipApi.get_delete_clip(params)
      },
      'GET_PROFILE_CAPABILITIES': async (_,params ) => {
        return await api.clipApi.get_profile_capabilities(params) 
      },
      'GET_ALL_USER_CLIPS': async ({state, commit}, params) => {
          commit('UPDATE_GET_CLIPS', await api.clipApi.get_all_user_clips(params));
      },
      'GET_ALL_USER_SOURCE_CLIPS': async ({state, commit}, params) => {
        return commit('UPDATE_GET_ALL_SOURCE_CLIPS', await api.clipApi.get_all_user_source_clips(params));
      },
      'GET_DOWNLOAD_CLIP': async (_,params ) => {
        return await api.clipApi.get_download_clip(params)
     },
      'GET_ALL_ID_CLIPS': async ({state, commit}, params) => {  
        commit('UPDATE_GET_CLIPS', await api.clipApi.get_all_id_clips(params));
    },
}
