export default {
  UPDATE_EVENTS_LIST(state, data = []) {
    state.eventsList = [...state.eventsList, ...data];
  },
  CLEAR_EVENTS_LIST(state) {
    state.eventsList = [];
  },
  SET_EVENT_REQUEST_OFFSET(state, offset = 0) {
    state.lastEventRequestOffset = offset;
  },
  RESET_EVENT_REQUEST_OFFSET(state) {
    state.lastEventRequestOffset = 0;
  },
  SET_EVENT_META(state, meta) {
    state.eventsMeta = meta;
  },
  CLEAR_EVENT_META(state) {
    state.eventsMeta = state.initialState?.eventsMeta;
  },
  UPDATE_EVENTS_CATEGORIES(state, data) {
    const getCategoryId = (categoryArr) => {
      const data = {};
      categoryArr.forEach((category) => {
        data[category.id] = category;
        if (category.children?.length) {
          Object.assign(data, getCategoryId(category.children));
        }
      });
      return data;
    };

    state.eventCategories = data;
    state.eventCategoriesById = getCategoryId(data);
  },
  UPDATE_FLAT_EVENTS_CATEGORIES_DICTIONARY(state, data) {
    state.flatEventsCategoriesDictionary = data;
    console.log(
      'UPDATE_EVENTS_CATEGORIES',
      state.flatEventsCategoriesDictionary
    );
  },
};
