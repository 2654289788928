import request from './request';
import Vue from 'vue';
import store from '../../store/store';
import {API} from '../../const';


const authApi = {

    get_channel_key() {
        let channel_host
        if (process?.env?.VUE_APP_CHANNEL_CONFIG_NAME){
            channel_host = process.env.VUE_APP_CHANNEL_CONFIG_NAME
        } else {
            channel_host = document.location.host.split('.')[0];
        }
        return request(channel_host + '.json', {}, 'get', API.API_KEYS_HOST)
            .then((res) => {
                store.cache.clear();
                return res
            })
    },
    get_api_key(params = {}) {
        if (Vue.$storage.get('api_key')) {
            return {
                data: {api_key: Vue.$storage.get('api_key')}
            }
        }
        Object.assign(params, {
            channel_key: Vue.$storage.get('channel_key')
        });
        return request('/get_api_key', params)
    },
    profile() {
        return request('/profile', {}, 'get');
    },
    login: function (params = {}) {
        return request('auth/login', params)
            .then((res) => {
                store.cache.clear();
                return res
            });
    },
    auth0_login: function (params = {}) {
        return request('auth/login/auth0/callback', params)
            .then((res) => {
                store.cache.clear();
                console.log('auth/login/auth0/callback response :>> ', res?.data);
                return res
            });
    },
    logout: function () {
        return request('auth/logout')
            .then(async () => {
                store.cache.clear();
                await resetAuthData();
            })
    },
    registration: function (params = {}) {
        return request('auth/register', params)
            .then((res) => {
                store.cache.clear();
                return res
            });
    },
    verify: function (params = {}) {
        return request('auth/email-verify', params);
    },
    passwordResetRequest: function (params = {}) {
        return request('auth/password-reset-request', params);
    },
    passwordReset: function (params = {}) {
        return request('auth/password-reset', params);
    },
    updateProfile: function (params = {}) {
        Object.assign(params, {_method: 'patch'});
        return request('profile', params);
    },
};

const resetAuthData = () => {
    Vue.$storage.set('api_key', null);
    return new Promise((resolve) => {
        store.dispatch('GET_API_KEY').then(() => {
            resolve(); //После переавторизации обновим странцицу
        })
    })

};

export default authApi;
