    //@author Lucas Mello <lucas.mello@icaromediagroup.com>
    //model to call clips api

import request from "./request";

export default {
  get_all_user_clips(params) {
    return request("video/clips", params, "get"); 
  },
  get_all_id_clips(params) { 
    return request(`video/clips/${params.id}`, params.api_key, "get"); 
  },
  get_all_user_source_clips(params) {
    return request(`/video/clip-source`, params, "get"); 
  },
  get_download_clip(params) {
    return request(`/video/clip/download/${params.id}`, params.api_key, "get"); 
  },
  post_send_clip(params) {    
    return request("/video/clip", params, "post"); 
  },
  delete_clip(params) {
    return request(`/video/clip/${params.id}`, params.api_key, "delete"); 
  },
  get_delete_clip(params) {
    return request(`/video/clip/delete/${params.id}`, params.api_key, "get"); 
  },
  get_profile_capabilities(params) {
    return request("profile", params, "get"); 
  },
};
