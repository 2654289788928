import request from './request';

export default {
    prepare: function( params = {}) {
        return request('stripe/prepare', params)
    },

    pay: function( params = {}) {
        return request('stripe/pay', params)
    },
    packages: function( params = {}) {
        return request('packages', params, 'get')
    },
    subscriptions: function( params = {}) {
        return request('subscriptions', params, 'get')
    },
    package: function( id ) {
        return request('packages/' + id, {}, 'get');
    },
    subscription: function( id ) {
        return request('subscriptions/' + id, {}, 'get');
    },

    orders: function ( params = {} ) {
        return request('orders', params, 'get');
    },
    orderById: function ({order_id}={}) {
        return request(`orders/${order_id}`, {},'get');
    },
    /**
     *
     * @param params {Object} {promo: String}
     * @returns {Promise<*>}
     */
    applyCoupon: function (params) {
        return request('apply_coupon', params, 'get')
    },
    /**
     * @param params {Object} {order_subscription_id: Number}
     * @returns {Promise<*>}
     */
    unsubscribe: function (params) {
        return request('stripe/unsubscribe', params, 'post');
    }
}
