const get = (key) => {
  if (localStorage) {
    key = key.trim();
    if (key === '') {
      return null;
    }
    const raw = localStorage.getItem(key);
    let parsed = null;
    if (!raw) return null;
    try {
      parsed = JSON.parse(raw);
    } catch (e) {
      localStorage.removeItem(key);
      return null;
    }
    let expiry = parsed.expiry;
    expiry = expiry + 1000 * 60 * 60 * 24 * 52; // 1 year
    if (
      Object.prototype.hasOwnProperty.call(parsed, 'expiry') &&
      expiry >= new Date().getTime()
    ) {
      return parsed.value;
    } else if (!Object.prototype.hasOwnProperty.call(parsed, 'expiry')) {
      return parsed.value;
    } else {
      return null;
    }
  } else {
    return null;
  }
};

const set = (key, value) => {
  if (localStorage) {
    key = key.trim();
    if (key === '') {
      return null;
    }
    let item = {
      value,
      expiry: new Date().getTime(),
    };
    localStorage.setItem(key, JSON.stringify(item));
    return get(key);
  } else {
    return null;
  }
};

const clear = (key) => {
  if (localStorage) {
    key = key.trim();
    if (key === '') {
      return;
    }
    localStorage.removeItem(key);
  } else {
    return null;
  }
};

export default {
  get,
  set,
  clear,
};
