import api from '../../api/api';
import {ejectAbortSignal} from "../../utils/helpers";

export default {
  'GET_VOD_ITEMS': async (state, params) => {
    let {token, props} = ejectAbortSignal(params);
    return await api.vodMediaApi.get_vod_medias(props, token);
  },
  'GET_VOD_ITEM': async (state, params) => {
    const {id, abortSignal:token} = params;
    return await api.vodMediaApi.get_vod_media(id, token);
  },
  'GET_VOD_CATEGORIES': async (state, params) => {
    return await api.vodMediaApi.get_media_categories(params);
  },
  'SEARCH_IN_VOD': async (state, params) => {
    let {token, props} = ejectAbortSignal(params);
    return await api.vodMediaApi.vod_search(props, token);
  }
}
