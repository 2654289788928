import Vue from 'vue';
import {Vue2Storage} from 'vue2-storage';
import Calendar from 'v-calendar/lib/components/calendar.umd';
import DatePicker from 'v-calendar/lib/components/date-picker.umd'; //https://vcalendar.io/installation.html
import 'bootstrap';
import store from '../store/store';
import vuexI18n from 'vuex-i18n';
import VueMeta from 'vue-meta'
import { MonthPicker } from 'vue-month-picker'
import { MonthPickerInput } from 'vue-month-picker'




Vue.component('calendar', Calendar);
Vue.component('date-picker', DatePicker);
Vue.use(VueMeta);
Vue.use(MonthPicker);
Vue.use(MonthPickerInput);
Vue.use(vuexI18n.plugin, store, {});

Vue.use(Vue2Storage, { // https://yarkovaleksei.github.io/vue2-storage/ru/
  prefix: 'rudy_', // Префикс хранения
  ttl: 60 * 60 * 24 * 1000 * 360 // Время хранения 360 дней
});


