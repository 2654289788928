import { invert } from 'lodash';
import en from './locales/en.json';
import fr from './locales/fr.json';

const schema = 'https:';
export const API = {
  API_HOST: `${schema}//${process.env.VUE_APP_URL}/api`,
  API_KEYS_HOST: `${schema}//${process.env.VUE_APP_URL}/storage/settings/`,
  MEDIA_HOST: `${schema}//${process.env.VUE_APP_MEDIA_URL}`,
};

export const ROUTER_PATHS = {
  events: '/events/',
};

export const LANGUAGES = {
  en,
  fr,
};

export const LOCAL_TIME_DISPLAYING = true;

export const PACKAGE_TYPES = {
  SINGLE: 'single',
  CATEGORY: 'category',
};

export const CHANNEL_TYPE = {
  conference: 'sport_conference',
  league: 'sport_league',
  entertainment: 'entertainment',
};

export const MEDIA_TYPE = {
  image: 1,
  live: 2,
  vod: 3,
  embedded: 4,
  thumbnails: 5,
  preview: 6,
  preview_alt: 7,
  embedded_preview: 8,
};

export const COMING = {
  LIVE_COMING: 'placeholder_live_comming',
  VOD_COMING: 'placeholder_vod_comming',
  VOD_NO: 'placeholder_vod_no',
};
export const ASSETS_TYPE = {
  preview: 'preview',
  image: 'image',
  video: 'video',
  embed: 'embed',
};
export const URL_QUERIES = {
  CATEGORY: 'category_id',
};
export const ALL_CATEGORY = {
  title: 'All',
  id: 0,
  parent_id: 0,
};

export const USER_ACTIONS = {
  HOVER: 'hover',
  CLICK: 'click',
};
export const MEDIA_TYPE_NAME_BY_ID = invert(MEDIA_TYPE);

export const MENU_POSITION = {
  header: 'header',
  footer: 'footer',
  both: 'both',
};

export const STREAM_STATUS = {
  all: 'all',
  vod: 'finished',
  live: 'live',
  upcoming: 'upcoming',
};

export const EVENT_TYPE = {
  free: 'free',
  paid: 'paid',
  register: 'register',
  ppv: 'ppv',
};

export const ORDER_STATUS_TEXT = {
  paid: 'Paid',
  inactive: 'Inactive',
  refunded: 'Refunded',
  active: 'Active',
};

export const TRANSACTION_STATUS = {
  created: 'New subscription',
  updated: 'Subscription Renewal',
};
export const RENEW_STATUS = {
  cancelled: 'Auto-Renewal Cancelled',
  active: 'Auto-Renewal',
};

export const ORDER_STATUS = {
  created: 'created',
  pending: 'pending',
  paid: 'paid',
  refunded: 'refunded',
  disabled: 'disabled',
  failed: 'failed',
  expired: 'expired',
};
export const ORDER_TYPE = {
  subscription: 'subscription',
  package: 'package',
  event: 'event',
};

export const ORDER_DISPLAY_TYPE = {
  grid: 'grid',
  list: 'list',
};
export const PLAYER_ERROR = {
  MAX_REFRESH_COUNT: 5,
  REFRESH_DURATION: 10000, // ms
};

export const AUTH_PROVIDERS = {
  FACEBOOK: 'facebook',
  GOOGLE: 'google',
};
export const AUTH_TYPE = {
  LOGIN: 'login',
  REGISTRATION: 'registration',
};

export const VALIDATION_REG_EXP = {
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  numberEmail: /^[\d()]+$/,
  rePhone: /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/,
};

export const ERROR = {
  WRONG_AUTH_DATA: 'Wrong auth data',
  ALREADY_LOGGED: 'You are already logged in',
  EMAIL_ALREADY_EXIST:
    'The email address you have entered is already registered.',
  VERIFY_FAILED: 'Verification failed.',
  EMAIL_ALREADY_VERIFY: 'Email already verified.',
  USER_NOT_FOUND: 'User not found.',
  RESET_PSWD_KEY_EXPIRED: 'Reset password key expired.',
  RESET_PSWD_FAILED: 'Reset password failed.',
  CANCELED: 'ERR_CANCELED',
  WRONG_PROMOCODE: 'Wrong promo code',
  GOOGLE_TOKEN: 'Google recaptcha token required',
  ROBOT: 'Google recaptcha token is invalid or you look like a robot',
  WRONG_EMAIL: 'A valid email address is required',
};

export const TIME_ZONES = [
  {
    name: 'EDT',
    value: 'America/Toronto',
  },
  {
    name: 'Bogota',
    value: 'America/Bogota',
  },
  {
    name: 'Buenos Aires',
    value: 'America/Argentina/Buenos_Aires',
  },
  {
    name: 'Sao Paulo',
    value: 'America/Sao_Paulo',
  },
  {
    name: 'Madrid',
    value: 'Europe/Madrid',
  },
  {
    name: 'Paris',
    value: 'Europe/Paris',
  },
  {
    name: 'Rome',
    value: 'Europe/Rome',
  },
  {
    name: 'Prague',
    value: 'Europe/Prague',
  },
  {
    name: 'Amsterdam',
    value: 'Europe/Amsterdam',
  },
  {
    name: 'Berlin',
    value: 'Europe/Berlin',
  },
  {
    name: 'Helsinki',
    value: 'Europe/Helsinki',
  },
  {
    name: 'Zurich',
    value: 'Europe/Zurich',
  },
  {
    name: 'Stockholm',
    value: 'Europe/Stockholm',
  },
  {
    name: 'Moscow',
    value: 'Europe/Moscow',
  },
  {
    name: 'Johannesburg',
    value: 'Africa/Johannesburg',
  },
  {
    name: 'Cairo',
    value: 'Africa/Cairo',
  },
  {
    name: 'Dubai',
    value: 'Asia/Dubai',
  },
  {
    name: 'Colombo',
    value: 'Asia/Colombo',
  },
  {
    name: 'Istanbul',
    value: 'Asia/Istanbul',
  },
  {
    name: 'Bangkok',
    value: 'Asia/Bangkok',
  },
  {
    name: 'Taipei',
    value: 'Asia/Taipei',
  },
  {
    name: 'Singapore',
    value: 'Asia/Singapore',
  },
  {
    name: 'Seoul',
    value: 'Asia/Seoul',
  },
  {
    name: 'Hong Kong',
    value: 'Asia/Hong_Kong',
  },
  {
    name: 'Sydney',
    value: 'Australia/Sydney',
  },
];

export const TIME_ZONE_FROM_SHORT = {
  PST: 'America/Los_Angeles',
  PDT: 'America/Santa_Isabel',
  MDT: 'America/Chihuahua',
  NZDT: 'Pacific/Auckland',
  AEST: 'Australia/Melbourne',
  ACST: 'Australia/Darwin',
  AWST: 'Australia/Perth',
  EEST: 'Africa/Tripoli',
  CEST: 'Africa/Ceuta',
  WEST: 'Europe/Lisbon',
  'GMT/UTC': 'Europe/London',
  ADT: 'America/Glace_Bay',
  EDT: 'America/Detroit',
  EST: 'America/Havana',
  CDT: 'America/Chicago',
};

export const OUTER_URLS = {
  AUTO_RENEWAL: 'https://usah.zendesk.com/hc/en-us/requests/new',
  EVENT_PREROLL:
    'https://rudy-media.azureedge.net/media/Chicken_Al_Pastor_All_The_Fire_FEB23_Launch_15.mp4',
  LIVE_DELAYED:
    'https://js-lib-yare-analytics.s3.us-west-2.amazonaws.com/placeholders/livestream_delayed.mp4',
  ARCHIVE_CURRENTLY_UNAVAILABLE:
    'https://js-lib-yare-analytics.s3.us-west-2.amazonaws.com/placeholders/archive_currently_unavailable.mp4',
  EVENT_UNAVAILABLE:
    'https://js-lib-yare-analytics.s3.us-west-2.amazonaws.com/placeholders/event_unavailable.mp4',
};
export const QUERY = {
  CHANNEL: 'channel',
  DIVISION: 'division',
  GROUP: 'group',
};

export const STRIPE_API_VERSION = '2022-08-01';
