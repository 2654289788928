import Vue from 'vue';
import VueRouter from 'vue-router';
import NotFound from './components/NotFound';
import store from './store/store';
import GoogleAnalytics from '@/components/base-components/Analytics/GoogleAnalytics';

Vue.use(VueRouter);

let router = new VueRouter({
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    return savedPosition ? savedPosition : { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/login', // -> /login
      name: 'login',
      meta: {
        title: 'Login',
      },
      component: () =>
        import(/* webpackChunkName: "auth-view" */ './views/LoginScreen.vue'),
    },
    {
      path: '/register',
      name: 'register',
      meta: {
        title: 'Registration',
      },
      component: () =>
        import(
          /* webpackChunkName: "auth-view" */ './views/RegistrationScreen.vue'
        ),
    },
    {
      path: '/verify-notice',
      name: 'verify-notice',
      meta: {
        title: 'Verify notice',
      },
      component: () =>
        import(
          /* webpackChunkName: "auth-view" */ './views/VerifyNoticeScreen.vue'
        ),
    },
    {
      path: '/verify-email', ///:email(.*)/:signature(.*)
      name: 'verify',
      meta: {
        title: 'Verify',
      },
      component: () =>
        import(/* webpackChunkName: "auth-view" */ './views/VerifyScreen.vue'),
    },
    {
      path: '/password-reset-request',
      name: 'password-reset-request',
      meta: {
        title: 'Password reset request',
      },
      component: () =>
        import(
          /* webpackChunkName: "auth-view" */ './views/PasswordResetRequestScreen.vue'
        ),
    },
    {
      path: '/password-reset',
      name: 'password-reset',
      meta: {
        title: 'Password reset',
      },
      component: () =>
        import(
          /* webpackChunkName: "auth-view" */ './views/PasswordResetScreen.vue'
        ),
    },
    {
      path: '/profile',
      name: 'profile',
      meta: {
        title: 'Profile',
      },
      component: () =>
        import(
          /* webpackChunkName: "profile-view" */ './views/ProfileScreen.vue'
        ),
      children: [
        {
          path: '',
          name: 'orders list',
          component: () =>
            import(
              /* webpackChunkName: "profile-view" */ './components/profile/OrdersList.vue'
            ),
          children: [
            {
              path: '',
              name: 'active orders',
              component: () =>
                import(
                  /* webpackChunkName: "profile-view" */ './components/profile/ActiveOrders.vue'
                ),
            },
            {
              path: 'transactions',
              name: 'transactions',
              component: () =>
                import(
                  /* webpackChunkName: "profile-view" */ './components/profile/HistoryOrdersList.vue'
                ),
            },
            {
              path: 'clips',
              name: 'clips',
              component: () =>
                import(
                  /* webpackChunkName: "profile-view" */ './components/profile/VideoClippingProfileComponent.vue'
                ),
            },
          ],
        },
        {
          path: 'edit',
          name: 'edit',
          meta: {
            title: 'Update profile',
          },
          component: () =>
            import(
              /* webpackChunkName: "profile-view" */ './components/profile/UpdateProfileComponent.vue'
            ),
        },
        {
          path: 'clips',
          name: 'clips',
          meta: {
            title: 'Clips profile',
          },
          component: () =>
            import(
              /* webpackChunkName: "schedule-view" */ './views/ProfileScreen.vue'
            ),
        },
      ],
    },
    {
      path: '/contacts',
      name: 'contacts',
      meta: {
        title: 'Contacts',
      },
      component: () =>
        import(
          /* webpackChunkName: "contacts-view" */ './views/ContactScreen.vue'
        ),
    },
    {
      path: '/buy',
      name: 'buy',
      meta: {
        title: 'Buy',
      },
      component: () =>
        import(/* webpackChunkName: "buy-view" */ './views/BuyScreen.vue'),
    },
    {
      path: '/select-package/:id(\\d+)',
      name: 'Select package',
      meta: {
        title: 'Select package',
      },
      component: () =>
        import(
          /* webpackChunkName: "buy-view" */ './views/SelectPackageScreen.vue'
        ),
    },
    {
      path: '/faq',
      name: 'faq',
      meta: {
        title: 'FAQ',
      },
      component: () =>
        import(/* webpackChunkName: "faq-view" */ './views/FaqScreen.vue'),
    },
    {
      path: '/simulate',
      name: 'simulate',
      meta: {
        title: 'Simulate',
      },
      component: () =>
        import(
          /* webpackChunkName: "simulate-view" */ './views/SimulateScreen.vue'
        ),
    },
    {
      path: '/404',
      name: 'ErrorPage',
      meta: {
        title: '404',
      },
      component: NotFound,
    },
    {
      path: '/about',
      name: 'about',
      meta: {
        title: 'About us',
      },
      component: () =>
        import(/* webpackChunkName: "faq-view" */ './views/AboutUsScreen.vue'),
    },
  ],
});

const NOT_REMEMBERED_PAGES = [
  'login',
  'register',
  'verify-notice',
  'password-reset-request',
  'password-reset',
  'ErrorPage',
  'verify',
];
const DISABLE_FOR_LOGGED = [
  '/login',
  '/register',
  '/password-reset-request',
  '/password-reset',
];
const PATH_TO_REDIRECT_IF_LOGGED = '/';

router.beforeEach((to, from, next) => {
  const logged = store.getters.isLogged;
  if (
    logged &&
    store.state.auth.user.temporary_password &&
    '/profile/edit' !== to.path
  ) {
    if ('/profile/edit' === from.path) {
      return false;
    }
    next({
      path: '/profile/edit',
    });
  } else if (logged && DISABLE_FOR_LOGGED.includes(to.path)) {
    next(PATH_TO_REDIRECT_IF_LOGGED);
  } else if (NOT_REMEMBERED_PAGES.includes(to.name)) {
    next();
  } else {
    Vue.$storage.set('last_page', to.path);
    Vue.$storage.set('last_page_query', to.query);
    Vue.$storage.set('last_page_params', to.params);
    next();
  }
});
router.afterEach((to, from) => {
  GoogleAnalytics.pageView(to.fullPath);
});

export default router;
