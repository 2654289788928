import api from '../../api/api';

export default {
    'GET_CHANNEL_KEY': async ({commit}) => {
        commit('UPDATE_CHANNEL_KEY', await api.authApi.get_channel_key())
    },
    'GET_API_KEY': async ({commit}) => {
        commit('UPDATE_API_KEY', await api.authApi.get_api_key())
    },
    'GET_PROFILE': async ({commit}) => {
        commit('UPDATE_USER_INFO', await api.authApi.profile())
    },
    'DO_LOGIN': async ({commit}, params) => {
        commit('UPDATE_USER_INFO', await api.authApi.login(params));
    },
    'DO_AUTH0_LOGIN': async ({commit}, params) => {
        commit('UPDATE_USER_INFO', await api.authApi.auth0_login(params));
    },
    'DO_REGISTRATION': async ({commit}, params) => {
        commit('UPDATE_USER_INFO', await api.authApi.registration(params));
    },
    'DO_LOGOUT': async ({commit}, params) => {
        commit('CLEAR_USER_INFO', await api.authApi.logout(params));
    },
    'DO_VERIFY': async (state, params) => {
        return await api.authApi.verify(params);
    },
    'DO_PASSWORD_RESET_REQUEST': async (state, params) => {
        return await api.authApi.passwordResetRequest(params);
    },
    'DO_PASSWORD_RESET': async (state, params) => {
        return await api.authApi.passwordReset(params);
    },
    'DO_UPDATE_PROFILE': async ({commit}, params) => {
        commit('UPDATE_USER_INFO', await api.authApi.updateProfile(params));
    },
}
