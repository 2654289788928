import { VALIDATION_REG_EXP } from '@/const';

function getType(fn) {
  var match = fn && fn.toString().match(/^\s*function (\w+)/);
  return match ? match[1] : '';
}
export function isSameType(type, expectedType) {
  return getType(type) === getType(expectedType);
}
export function loadScript(url, cb) {
  var tag = document.createElement('script');
  tag.src = url;
  tag.onload = () => cb?.();
  var firstScriptTag = document.getElementsByTagName('script')[0];
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
}
export function ejectAbortSignal(params) {
  let token = undefined;
  if (params.abortSignal) {
    token = params.abortSignal;
    delete params.abortSignal;
  }
  return {
    token,
    props: params,
  };
}
export function numberSuffix(num) {
  let suffix = ['th', 'st', 'nd', 'rd'],
    idx = (num - 20) % 10,
    s = suffix[idx] || suffix[0];
  return `${num}${s}`;
}

export function parseStringBySeparatorToHtml(
  str,
  separator = '\r\n',
  tag = 'p'
) {
  if (!str) return;
  const strArr = str.split(separator);
  return strArr.reduce((total, next) => {
    return `${total}<${tag}>${next}</${tag}>`;
  }, '');
}

export function validateEmail(email) {
  return (
    VALIDATION_REG_EXP.email.test(email) ||
    VALIDATION_REG_EXP.numberEmail.test(email)
  );
}

export function getAwayHomeTeams(event) {
  const homeId = event.home_team_id;
  const homeTeam = {};
  const awayTeam = {};

  event.participants?.forEach((team) => {
    let teamObj;
    if (homeId) {
      teamObj = Number(team.id) === Number(homeId) ? homeTeam : awayTeam;
    } else {
      teamObj = Object.keys(awayTeam).length ? homeTeam : awayTeam;
    }
    teamObj.name = team.name;
    teamObj.img = team.images;
    teamObj.team_id = team.id;
    teamObj.color = team.color;
  });

  return {
    homeTeam,
    awayTeam,
  };
}

export function flattenObjectArrayByKey(objArr, key) {
  let flattenedArr = [];

  objArr.forEach((item) => {
    if (Array.isArray(item[key])) {
      flattenedArr.push({
        id: item.id,
        image: item.image,
        name: item.name,
      });
      flattenedArr = flattenedArr.concat(
        flattenObjectArrayByKey(item[key], key)
      );
    } else {
      flattenedArr.push(item);
    }
  });

  return flattenedArr;
}

export function teamName(name) {
  return name.replace('University of', '').replace('University', '').trim();
}

/**
 * @param {string} dateString
 * @returns {Date}
 */
export function getNewDateFromString(dateString) {
  return new Date(dateString.replace(/-/g, '/'));
}
