import api from '../../api/api';
import { cacheAction } from 'vuex-cache';

export default {
  'PAYMENT_PREPARE': async (state, params) => {
    return await api.paymentApi.prepare(params);
  },
  'PAYMENT_PAY': async (state, params) => {
    return await api.paymentApi.pay(params);
  },
  'GET_PACKAGES_CACHED': cacheAction(({ cache, commit }, params) => {
    cache.dispatch('GET_PACKAGES', params)
        .then((packages) => {
          commit('UPDATE_PACKAGES_LIST', packages);
        })
  }),
  'GET_PACKAGES': async ({commit}, params) => {
    const packages = await api.paymentApi.packages(params);
    commit('UPDATE_PACKAGES_LIST', packages);
    return packages;
  },
  'GET_SUBSCRIPTIONS_CACHED': cacheAction(({ cache, commit }, params) => {
    cache.dispatch('GET_SUBSCRIPTIONS', params)
        .then((packages) => {
          commit('UPDATE_SUBSCRIPTIONS_LIST', packages);
        })
  }),
  'GET_SUBSCRIPTIONS': async ({commit}, params) => {
    const subscriptions = await api.paymentApi.subscriptions(params);
    commit('UPDATE_SUBSCRIPTIONS_LIST', subscriptions);
    return  subscriptions
  },
  'GET_EVENT_PACKAGES': async ({commit}, params) => {
    return await api.paymentApi.packages(params);
  },
  'GET_PACKAGE': async (state, params) => {
    return await api.paymentApi.package(params);
  },
  'GET_SUBSCRIPTION': async (state, params) => {
    return await api.paymentApi.subscription(params);
  },
  'GET_ORDERS': async ({commit}, params) => {
    let orders = await api.paymentApi.orders(params);
    //commit('UPDATE_ORDERS_LIST', orders);
    return orders.data;
  },
  'GET_ORDER_BY_ID': async (_, {order_id}={}) => {
    if (!order_id) return false;
    return await api.paymentApi.orderById({order_id})
  },
  'APPLY_COUPON': async (_, params) => {
    return await api.paymentApi.applyCoupon(params)
  },
  'UNSUBSCRIBE': async (_, params) => {
    return await api.paymentApi.unsubscribe(params)
  }
}
