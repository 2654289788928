export default {
  getChannelRoute(state){
    let newRoute = [];
    state?.pages?.forEach(page => {
      newRoute.push({
        path: page.url,
        name: page.name,
        meta: {
          title: page.name,
          scrollToTop: true,
          id: page.id,
        },
        component: () => import(/* webpackChunkName: "schedule-view" */ '../../views/CustomPageScreen.vue'),
      })
    });
    return newRoute;
  },
}

