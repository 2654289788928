export default {
  'UPDATE_GET_CLIPS'(state, data) {
    state.clips = data.data;
  },
  'UPDATE_GET_ALL_SOURCE_CLIPS'(state, data) {
    if (Array.isArray(data?.data)) {
      state.sourceClips = data.data;
    }
  }
}
