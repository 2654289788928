import {initialState} from './calendar';

export default {
    'UPDATE_LIST_PARAMS'(state, data){
        state.startListDate = data.start;
        state.endListDate = data.end;
    },
    'UPDATE_CALENDAR_PARAMS'(state, data){
        state.startCalendarDate = data + 86400;
    },
    'RESET_EVENT_PARAMS'(state){
        state.startCalendarDate = null;
        state.startListDate = null;
        state.endListDate = null;
    },
};
