import mutations from './mutations';

export const initialState = {
    startListDate: null,
    endListDate: null,
    startCalendarDate: null,
};

export default {
    state: {
        ...initialState,
    },
    mutations
};
