import request from './request';

export default {
    get_participants(params = {}) {
        return request('/participants', params, 'get');
    },
    get_participants_group(params = {}){
        return request('/participant-groups', params, 'get')
    }
}
