import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export const initialState = {
  clips: [],
  sourceClips: [],
};

export default {
  state: {
    ...initialState,
  },
  mutations,
  actions,
  getters
}
