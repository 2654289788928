import mutations from './mutations';
import actions from './actions';
import getters from './getters'

export const initialState = {};

export default {
    state: {
        customForm: {},
        ...initialState
    },
    mutations,
    actions,
    getters
}
