import mutations from './mutations';
import actions from './actions';

export const initialState = {
  eventsList: [],
  lastEventRequestOffset: 0,
  eventsMeta: {},
  eventCategories: [],
  eventCategoriesById: {},
  flatEventsCategoriesDictionary: [],
};

export default {
  state: {
    ...initialState,
  },
  mutations,
  actions,
};
