import api from '../../api/api';

export default {
    'GET_PARTICIPANTS_LIST': async ({commit}, params) => {
        let participants = await api.participantApi.get_participants(params);
        commit('UPDATE_PARTICIPANTS_META', participants.meta.total);
        commit('UPDATE_PARTICIPANTS_LIST', participants.data);
        return participants;
    },
    'GET_PARTICIPANTS_GROUP': async ({commit}) => {
        let groups = await api.participantApi.get_participants_group();
        commit('UPDATE_PARTICIPANTS_GROUPS', groups);
        return groups;

    },
    'GET_ALL_PARTICIPANTS': async ({commit}) => {
        const unickMap = new Map()

        let initialParticipants = await api.participantApi.get_participants({limit: 100, offset: 0});
        const {data, meta:{ limit, offset, total}} = initialParticipants;
        let newOffset = limit + offset;
        const requests = [];
        while (newOffset < total) {
            requests.push(api.participantApi.get_participants({limit, offset: newOffset}))
            newOffset += limit
        }
        data?.forEach(item => unickMap.set(item.id, item))
        await Promise.allSettled(requests)
            .then((results) => results.forEach(({value}) => {
                value?.data?.forEach?.(item => unickMap.set(item.id, item))
            }))

        commit('UPDATE_PARTICIPANTS_META', total);
        commit('UPDATE_PARTICIPANTS_LIST', Array.from(unickMap.values()));
    }
}
