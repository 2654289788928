import api from '../../api/api';

export default {
  'GET_CHANNEL_SETTINGS': async ({commit}) => {
    commit('UPDATE_CHANNEL_SETTINGS', await api.channelsApi.get_channel_settings());
  },
  'GET_CHANNEL_MENU': async ({commit}) => {
    commit('UPDATE_CHANNEL_MENU', await api.channelsApi.get_channel_menu())
  },
  'GET_CHANNEL_SLIDES': async () => {
    return await api.channelsApi.get_channel_slides()
  },
  'GET_CHANNEL_PAGES': async ({commit}) => {
    commit('UPDATE_CHANNEL_PAGES', await api.channelsApi.get_channel_pages())
  },
  'GET_CHANNEL_PAGE': async (state, params) => {
    return await api.channelsApi.get_channel_page(params);
  },
  'GET_COLOR_SETTINGS': async ({commit}) => {
    commit('UPDATE_CHANNEL_COLORS', await api.channelsApi.get_channel_colors());
  }
}
