import Vue from 'vue';
import router from './routers';
import store from './store/store';

import './utils/filters';
import './utils/plugins';
import './utils/injections';
import './utils/social';
import './utils/directives';
import './assets/css/main.scss';
import { CHANNEL_TYPE } from './const';
import Loader from './components/Loader';

const APP_BUILDERS = {
  [CHANNEL_TYPE.league]: {
    routes: './league/leagueRouters',
    renderedApp: './league/LeagueApp',
  },
  [CHANNEL_TYPE.conference]: {
    routes: './conference/conferenceRouters',
    renderedApp: './conference/ConferenceApp',
  },
  [CHANNEL_TYPE.entertainment]: {
    routes: '',
    renderedApp: './entertainment/EntertainmentApp',
  },
};

window.vm = new Vue({
  el: '#app',
  components: { Loader },
  template: `<div id='app' class='d-flex align-items-center justify-content-center' :style="{height: '100vh'}"><Loader/></div>`,
});

store
  .dispatch('GET_CHANNEL_KEY')
  .then(() =>
    store.dispatch('GET_API_KEY', {
      channel_key: Vue.$storage.get('channel_key'),
    })
  )
  .then(() =>
    Promise.allSettled([
      store.dispatch('GET_CHANNEL_MENU'),
      store.dispatch('GET_CHANNEL_PAGES'),
      store.dispatch('GET_COLOR_SETTINGS'),
      store.dispatch('GET_CHANNEL_SETTINGS'),
      store.dispatch('GET_PROFILE'),
    ])
  )
  .catch((err) => {
    store.commit('UPDATE_USER_INFO', null);
    return err;
  })
  .finally(async () => {
    let renderApp,
      appRoutes = [];
    const dataForBuild = APP_BUILDERS[Vue.$storage.get('content_type')];

    await Promise.allSettled([
      import(`${dataForBuild.renderedApp}`)
        .then((m) => (renderApp = m.default))
        .catch((e) => console.warn('app file not found')),
      import(`${dataForBuild.routes}`)
        .then((m) => (appRoutes = m.default))
        .catch((e) => console.warn('routers file not found')),
    ]);

    router.addRoutes([
      ...appRoutes,
      ...(store.getters.getChannelRoute || []),
      {
        path: '*',
        redirect: '/404',
      },
    ]);

    window.vm = new Vue({
      router,
      store,
      render: (h) => h(renderApp),
    }).$mount('#app');
  });
