export default {
    'UPDATE_PACKAGES_LIST'(state, data){
        state.packagesList = data.data;
    },
    'UPDATE_SUBSCRIPTIONS_LIST'(state, data){
        state.subscriptionsList = data.data;
    },
    'UPDATE_ORDERS_LIST'(state, data){
        state.ordersList = data.data;
    },
}
