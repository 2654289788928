import {EVENT_TYPE, ORDER_STATUS, ORDER_TYPE} from "../../const";
import api from "../../api/api";
import {getAwayHomeTeams, teamName} from "../../utils/helpers";
import Vue from "vue";

const BASE_PRICE_FIELD_NAME = 'base_price';
const FEE_PRICE_FIELD_NAME = 'service_fee';

export default {
  'GET_INFO_FOR_ORDER_BY_TYPE': async (state, params) => {
    const {
      type,
      id, // package_id, event_id, subscription_id
      single_event_package_id: single_id,
      participant_id,
    } = params;

    if (!id) {
      return Promise.reject('id not selected')
    }

    if (type === ORDER_TYPE.event) {
      const promise = Promise.allSettled([
        api.eventsApi.get_event(id),
        single_id && api.paymentApi.package(single_id)
      ])
      return promise.then(([{value: event, reason: eventReason}, {value: pack}]) => {
        if (eventReason || (event.data?.type === EVENT_TYPE.paid && !single_id)) {
          throw new Error(JSON.stringify(eventReason))
        }

        const isPaid = event?.data?.type === EVENT_TYPE.paid;
        let paymentData = isPaid ? pack?.data : event?.data;
        const teams = getAwayHomeTeams(event.data);

        let name;
        if (event.data.participants.length === 2) {
          name = `${teamName(teams.awayTeam.name)} @ ${teamName(teams.homeTeam.name)}`
        } else {
          name = event.data.name
        }

        const orderData = {
          price: paymentData?.price,
          basePrice: paymentData?.[BASE_PRICE_FIELD_NAME] || paymentData?.price,
          fee: paymentData?.[FEE_PRICE_FIELD_NAME] || 0,
          name,
          event_utc_ts: event.data.event_utc_ts,
          exist: event.data.has_access,
        }
        single_id && (orderData.single_event_package_id = single_id)

        const trackData = {
          type: `${ORDER_TYPE.event}${!isPaid ? ' - ' + orderData.type : ''}`,
          name: isPaid ? `${event?.data.name} [package: ${pack?.data?.name}]` : event?.data.name,
          id: isPaid ? `${event?.data.id} [package: ${pack?.data?.id}]` : event?.data.id,
          item: paymentData,
          tax: paymentData?.service_fee
        }
        return {
          trackData,
          orderData,
        }
      })

    } else if (type === ORDER_TYPE.package) {
      const promise = Promise.allSettled([
        api.paymentApi.package(id),
        api.paymentApi.orders({type: ORDER_TYPE.package, status: ORDER_STATUS.paid})
      ])

      return promise.then(([{value: packageData, reason: packReason}, {value: orders}]) => {
        if (packReason) {
          throw new Error(JSON.stringify(packReason));
        }
        let pack = packageData?.data;

        let exist = orders?.data?.find(item => {
          if (item.product && (Number(item.product.id) === Number(id))) {
            return !pack.is_team_pass || pack.is_team_pass && item.participant_id === participant_id;
          }
        });

        if (!exist && pack.is_team_pass && !participant_id) {
          throw new Error('Package is TeamPass but team not selected');
        }

        const trackData = {
          type: ORDER_TYPE.package,
          name: pack.name,
          id: pack.id,
          item: pack,
          tax: pack.service_fee
        }

        const orderData = {
          exist,
          price: pack.price,
          basePrice: pack?.[BASE_PRICE_FIELD_NAME] || pack?.price,
          fee: pack?.[FEE_PRICE_FIELD_NAME] || 0,
          name: pack?.name,
        }

        return {
          trackData,
          orderData,
        }
      })
    } else if (type === ORDER_TYPE.subscription) {
      const subscribePromise = Promise.allSettled([
        api.paymentApi.subscription(id),
        api.paymentApi.orders({type: ORDER_TYPE.subscription, status: ORDER_STATUS.paid})
      ])

      return subscribePromise.then(([{value: sub, reason: subReason}, {value: orders}]) => {
        if (subReason) {
          throw new Error(JSON.stringify(subReason))
        }
        const subscription = sub?.data;
        let exist = orders?.data?.find(item => {
          return !!(item['product'] && (Number(item['product'].id) === Number(id)));
        });

        const trackData = {
          type: ORDER_TYPE.subscription,
          name: subscription?.name,
          id: subscription?.id,
          item: subscription,
          tax: subscription.service_fee
        }

        const orderData = {
          price: subscription?.price,
          basePrice: subscription?.[BASE_PRICE_FIELD_NAME] || subscription?.price,
          fee: subscription?.[FEE_PRICE_FIELD_NAME] || 0,
          exist,
          name: subscription?.name,
        }

        return {
          trackData,
          orderData,
        }
      })
    }
  },
  'ORDER_PAY': async (state, {
    order_id,
    payment_method,
    promocode,
    event,
    type,
    single_event_package_id,
    id
  }) => {
    let payObject = {
      order_id: order_id,
      payment_method: payment_method
    };
    promocode && (payObject.promocode = promocode)
    single_event_package_id && (payObject.single_event_package_id = single_event_package_id)

    let interval;
    const clearIntervalCb = () => {
      clearInterval(interval)
    }

    function statusCheck(event_id){
       return new Promise((resolve, reject) => {
        interval = setInterval(() =>  {
          api.eventsApi.get_event(event_id).then((data) => {
            if (data.data && data.data.has_access) {
              clearIntervalCb();
              resolve(data)
            }
          }).catch((error) => {
            clearIntervalCb();
            reject(error)
          });
        }, 5000)
      })
    }

    function orderCheck(order) {
      return new Promise((resolve, reject) => {
        interval = setInterval(() => {
          api.paymentApi.orderById({order_id: order?.order_id})
              .then((res) => {
                if (res?.data?.status === ORDER_STATUS.paid) {
                  resolve(res);
                } else if (res?.data?.status === ORDER_STATUS.failed){
                  reject({...res?.data, popup: true})
                }
              })
              .catch((err) => {reject(err)})
              .finally(clearIntervalCb)
        }, 5000)
      })
    }

    return  {
      payPromise: new Promise((resolve, reject) => {
        api.paymentApi.pay(payObject)
            .then(res => {
              if (event) {
                event.complete('success');
              }
              let checkPromise;
              if (type === ORDER_TYPE.event) {
                checkPromise = statusCheck(id);
              } else if ((Vue.$storage.get('last_page')?.indexOf('watch') > -1) && (Vue.$storage.get('last_page_params').id)) {
                checkPromise = statusCheck(Vue.$storage.get('last_page_params').id);
              } else {
                checkPromise = orderCheck(res?.data)
              }
              checkPromise
                  .then(resolve)
                  .catch(reject)
            })
            .catch(error => {
          if (event) {
            event.complete('fail');
          }
          reject(error)
        });
      }),
      clearIntervalCb
    }
  }
}
