export default {
  isLogged: (state) =>{
    return (!state.user) ? false : true;
  },
  //@author Lucas Mello <lucas.mello@icaromediagroup.com>
  // get the user name for the clip api
  getUser: (state) =>{
    return state.user;
  },
}

