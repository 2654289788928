import request from './request';

export default {
    get_custom_form(alias) {
        return request('/custom-forms/' + alias, {}, 'get')
            .then(res => {
                return res;
            });
    }
}
