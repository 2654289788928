export default {
  UPDATE_USER_FAVORITES(state, data) {
    state.favorites = data.data;
  },
  UPDATE_REMEMBER_FAVORITES(state, { index, data }) {
    state.favorites[index] = data;
  },
  UPDATE_SELECT_FAVORITE(state, data ) {
    state.selectedFavorite = data;
  },
};
