import request from './request';

export default {
    get_channel_settings() {
        return request('/settings', {}, 'get');
    },
    get_channel_menu() {
        return request('/menu', {}, 'get');
    },
    get_channel_slides() {
        return request('/slides', {}, 'get');
    },
    get_channel_pages() {
        return request('/pages', {}, 'get');
    },
    get_channel_page(id) {
        return request('/pages/' + id, {}, 'get');
    },
    get_channel_colors(){
        return request('/color-settings/web', {}, 'get')
    }
}
