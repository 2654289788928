import api from '../../api/api';
import { flattenObjectArrayByKey } from '@/utils/helpers';

export default {
  GET_EVENTS_LIST: async ({ state, commit }, params = {}) => {
    const { requestParams = params, callbacks } = params;

    const res = await api.eventsApi.get_events_list(requestParams);
    callbacks?.onSuccess?.(res);
    callbacks?.onFinally?.();
    return res;
  },
  GET_EVENTS_FAVORITES_LIST: async ({ state, commit }, params = {}) => {
    const { requestParams = params, callbacks } = params;

    const res = await api.eventsApi.get_events_favorites_list(requestParams);
    callbacks?.onSuccess?.(res);
    callbacks?.onFinally?.();
    return res;
  },
  GET_EVENT: async (state, params) => {
    return await api.eventsApi.get_event(params);
  },
  DOWNLOAD_EVENT: async (_, params) => {
    return await api.eventsApi.download_event(params);
  },
  GET_EVENT_CATEGORIES: async ({ state, commit }, params) => {
    api.eventsApi
      .get_event_categories(params)
      .then(({ data = [] }) => {
        commit('UPDATE_EVENTS_CATEGORIES', data);
        // flatten the data for dictionary reference
        commit(
          'UPDATE_FLAT_EVENTS_CATEGORIES_DICTIONARY',
          flattenObjectArrayByKey(data, 'children')
        );
      })
      .catch((err) => {});
  },
};
